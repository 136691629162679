@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  font-family: "Quicksand";
}

html, body {
  box-sizing: border-box;
  max-width: 100vw !important;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: 'Quicksand';
  margin: 0; 
  height: 100%; 
  overflow: hidden
}
h2{
  // color: #FFA500;
  font-size: 1.2rem; 
  font-weight:600;
   margin: 0
}

.black{
  color: #000000;
}
.red{
  color:#df0606;
}

.site-layout-content{
  min-height: 85vh; 
  background-color: #FFFFFF ;
  padding: 2vw;
  align-items: center;
  justify-content: center;
}
.content-header{
  height:14vh;
  background-color: white;
}
.content-main{
  height:86vh
}
.content-main-second{
  background-color: #FFFFFF ;
  min-height: 86vh; 
  padding: 1vh;
  align-items: center;
  justify-content: center;
}

.site-layout-first{
  background-color: #FFFFFF ;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.site-layout-second{
  background-color: #FFFFFF ;
  min-height: 80vh; 
  padding: 2vw;
  align-items: center;
  justify-content: center;
}

.form-login{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10vh;
}
.login-header{
  font-weight: 900;
  font-size: x-large;
}
.left {
  margin-right: auto;
}

.left img {
  width: 120px;
}

.login-form {
  min-width: 300px;
  max-width: 500px;
}

.login-form-button {
  width: 100%;
}

.green-button{
  background-color: #39CC9F;
  border: none;
}

.green-button:hover{
  background-color: #4CBB7F;
}

.side-navbar{
  height: 100vh;
  width: 2vw;
  background: #212121
}

iframe {
  width:100%;
  height: 100%;
  overflow: hidden;
}

.mygames-header{
  // background: #F5F5F5;
  // background: #4CBB7F;
  padding:20px 20px 0px 20px;
  // margin-bottom: 30px;
  margin-top: -20px;
  color: #F5F5F5;
}
.mygames-header-1{
  margin-bottom: 8vh;
}
.mygames-h1{
  text-align: center;
  font-size: 2.5em;
  // margin: 8vh 0vh;
  margin: 0vh;
  color:#df0606;
  // border: 1px solid #212121;
}
.mygames-h2{
  text-align: center;
  font-size: 2em;

}
.module-description{
// background-color: #515b5d;
background-color: #4CBB7F;
// color:#fffbfb;
color:white;
text-align: center;
padding: 10px;
}
.module-button-row{
  margin: 5vh;
}
.carousel-box{
  background-color: rgba(0, 0, 0, 0.248);
  border: 1px solid #4CBB7F
}

.chapter-sider{
  background: #F5F5F5 0% 0% no-repeat padding-box;
  height: 86vh;
}
.tutorial-chapter-list{
  background: #F5F5F5 0% 0% no-repeat padding-box;
  height: 68vh;
  overflow: auto;
}


.no-margin-padding{
  margin:0;
  padding: 0;
}
.flex-space-between-center{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.performance-h1{
  font-size: 28px;
  color: #212121;
font-weight: 500;
}
.performance-h2{
  font-size: 20px;
  color: #212121;
  font-weight: 500;
}
.performance-parameters{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin:7px 0px 7px 0px;
}

.performance-red-dot{
  height: 18px;
  width: 18px;
  background-color: #E04F4F;
  border-radius: 50%;
  margin-right: 6px;
}

.performance-orange-dot{
  height: 18px;
  width: 18px;
  background-color:#FFA500;
  border-radius: 50%;
  margin-right: 6px;
}

.performance-green-dot{
  height: 18px;
  width: 18px;
  background-color: #3BB44A;
  border-radius: 50%;
  margin-right: 6px;
}
.flex{
  display: flex;

}
.flex-center{
  display: flex;
  align-items: center;
}
.level-card{
  border: #212121 2px solid;
  // height:400px;
  width:200px;
  
}
.level-card-body{
  border-bottom: #212121 2px solid;
  border-top: #212121 2px solid;
  padding: 15px;

}
.level-card-title{
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}

.practice-level-text{
  padding:20px;
  text-align: center;
  font-size: 28px;
  font-weight: 500;

}
.green-text{
  color:#4CBB7F
}

.module-h2{
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 7vh;
}
.text-align-center{
  text-align: center;
}
.loading{
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  min-height: 80vh; 
  padding: 2vw;
}
@primary-color: #4CBB7F;